import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from 'shared/constants';
import useLocale from 'shared/hooks/useLocale';
import useMutation from 'shared/hooks/useMutation';
import { CalculationResultsItem } from 'shared/types';
import mapCalculationResult from 'shared/utils/mapCalculationResult';
import { SteelCalculationsListRequest } from '../types';
import { parseJson } from '../../../shared/utils/jsonUtils';
import { getValueFromSessionStorage } from '../../../shared/utils/sessionStorageUtils';
import { SESSION_STORAGE_KEYS } from '../../../shared/sessionStorageUtils';

const useGetSteelCalculationsList = () => {
  const { t } = useTranslation();
  const locale = useLocale();

  const product = parseJson(getValueFromSessionStorage(SESSION_STORAGE_KEYS.product) || '');
  const structuralElements = parseJson(getValueFromSessionStorage(SESSION_STORAGE_KEYS.steelStructuralElements) || '');
  const protectedSides =  Number(getValueFromSessionStorage(SESSION_STORAGE_KEYS.steelProtectedSides)) || undefined;
  const fireRating = parseJson(getValueFromSessionStorage(SESSION_STORAGE_KEYS.steelFireRating) || '');
  const environment = parseJson(getValueFromSessionStorage(SESSION_STORAGE_KEYS.steelEnvironment) || '');

  const productCategory = product?.category;
  const orientationId = structuralElements?.orientation;
  const fireRatingId = fireRating?.fireRating;
  const totalAreaToProtectM2 = environment?.areaToProtect;
  const criticalTemperatureId = fireRating?.criticalTemperature;
  const criticalTemperatureC = fireRating?.customCriticalTemperature;
  const sectionFactor = structuralElements?.sectionFactor;
  const crossSectionalShapeId = structuralElements?.sectionType;
  const profileSubTypeId = structuralElements?.profileSubtype;
  const protectedSideId = protectedSides;
  const environmentHumidity = environment?.humidity;
  const environmentTemperature = environment?.temperature;
  const wastage = environment?.wastage;

  const request: SteelCalculationsListRequest = {
    orientationId,
    fireRatingId,
    totalAreaToProtectM2: Number(totalAreaToProtectM2),
    wastage: Number(wastage),
    criticalTemperatureId: Number(criticalTemperatureId),
    criticalTemperatureC: Number(criticalTemperatureC),
    sectionFactor: Number(sectionFactor),
    crossSectionalShapeId: Number(crossSectionalShapeId),
    profileSubTypeId: Number(profileSubTypeId),
    protectedSideId,
    environmentHumidity,
    environmentTemperature,
    productCategory,
  };

  const { mutate, isLoading, data } = useMutation<
    SteelCalculationsListRequest,
    CalculationResultsItem[]
  >(`${API_BASE_URL}v1/products/steel/${locale}/calculation-results`, request);

  const productsWithCalculations = data?.map((product) => ({
    ...product,
    calculationResult: mapCalculationResult(t, product.calculationResult),
    calculationInputParameters : product.calculationInputParameters
  }));

  return {
    productsWithCalculations,
    getSteelCalculationsList: mutate,
    isLoading
  };
};
export default useGetSteelCalculationsList;
