import useQuery from 'shared/hooks/useQuery';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { getProtectedSidesType } from '../protected-sides/logic';
import { ProtectedSidesItem } from '../types';
import { parseJson } from '../../../shared/utils/jsonUtils';
import { getValueFromSessionStorage } from '../../../shared/utils/sessionStorageUtils';

export interface StructuralElement {
  profileShape:  string | number,
  orientation: number,
  profileType: string | null,
  profileSubtype: string | null,
}

const useGetSteelProtectedSides = (structuralElements: StructuralElement) => {
  const product = parseJson(getValueFromSessionStorage(SESSION_STORAGE_KEYS.product) || '');

  const protectedSideType = getProtectedSidesType(product);

  const { data, error, isLoading, isError } = useQuery<ProtectedSidesItem[]>(
    'steel-protected-sides',
    `v1/steel-elements/profile-shapes/${structuralElements?.profileSubtype}/${protectedSideType}/profile-protected-sides`,
     Object.values(structuralElements),
    {
      enabled: !!structuralElements.profileSubtype
    }
  );

  return {
    steelProtectedSides: data,
    error,
    isLoading,
    isError,
  };
};

export default useGetSteelProtectedSides;
