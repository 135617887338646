import { useTranslation } from 'react-i18next';
import FormikSelectCard from 'components/select-card/formik-select-card';
import { ConcreteStructuralElementsValues, ConcreteOrientation } from 'pages/concrete/types';
import useIsMobile from 'shared/hooks/useIsMobile';
import AxisInformationInputs from '../axis-information-inputs';
import DimensionsInputs from '../dimensions-inputs';
import { CONCRETE_ORIENTATION_ICONS } from './constants';
import { CardsWrapper, Title } from './styles';
import useGetConcreteStructuresIds from '../../../../../shared/hooks/useGetConcreteStructuresIds';

interface OrientationsProps {
  values: ConcreteStructuralElementsValues;
  orientations: ConcreteOrientation[];
}
const Orientations = ({ values, orientations }: OrientationsProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { beamOrientationId, columnOrientationId } = useGetConcreteStructuresIds();

  const isBeamOrColumn =
    values.orientation === beamOrientationId ||
    values.orientation === columnOrientationId;

  return (
    <>
      <Title title={t('concrete.structuralElements.title')} displayContactSupport/>
      <CardsWrapper>
        {orientations.map((orientation) => (
          <div key={orientation.id}>
            <FormikSelectCard
              title={t(`concrete.structuralElements.${orientation.name}`)}
              selected={values.orientation === orientation.id}
              name="orientation"
              value={orientation.id}
              iconPng={CONCRETE_ORIENTATION_ICONS[orientation.name]}
            />
            {isMobile &&
              isBeamOrColumn &&
              values.orientation === orientation.id && <DimensionsInputs />}
            {isMobile && values.orientation === orientation.id && (
              <AxisInformationInputs />
            )}
          </div>
        ))}
      </CardsWrapper>
      {!isMobile && isBeamOrColumn && <DimensionsInputs />}
    </>
  );
};

export default Orientations;
