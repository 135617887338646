import { css, styled } from 'styled-components';
import { AxisIcon } from 'assets/concrete-structural-elements';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

const IMAGE_WIDTH = '140px';

export const ContentWrapper = styled.div`
  ${grid.twoColumnFitContentInFirst};
  border-top: ${({ theme }) => theme.border.default};
`;

export const Wrapper = styled.div`
  display: flex;
  gap: ${spacing.xl};
  padding: ${spacing.xl} 0 ${spacing['2xl']};
  width: 100%;

  ${breakpoint.mobile} {
    flex-direction: row-reverse;
    margin-top: ${spacing.s};
    padding: 0;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s};
  padding-bottom: ${spacing['2xl']};

  ${breakpoint.mobile} {
    margin-top: ${spacing.s};
    padding-bottom: ${spacing.m};
  }
`;

export const StyledAxisIcon = styled.img`
  min-width: ${IMAGE_WIDTH};
  object-fit: contain;
  width: ${IMAGE_WIDTH};
`;

export const DisclaimerWrapper = styled.div<{
  $isMobile: boolean;
}>`
  ${({ $isMobile }) => disclaimerWrapperStyles($isMobile)};
`;

const disclaimerWrapperStyles = (isMobile: boolean) => css`
  margin-top: ${isMobile ? 0 : spacing.xl};
`;
