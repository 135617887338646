import {
  LoaderOverlay,
  SpinningLoader,
  ComponentLoaderOverlay,
} from './styles';

interface LoaderProps {
  fullScreen?: boolean;
}

const Loader = ({ fullScreen = true }: LoaderProps) =>
  fullScreen ? (
    <LoaderOverlay>
      <SpinningLoader />
    </LoaderOverlay>
  ) : (
    <ComponentLoaderOverlay>
      <SpinningLoader />
    </ComponentLoaderOverlay>
  );

export default Loader;
