import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { CUSTOM_PROFILE } from '../../../pages/steel/constants';
import useIsMobile from '../../../shared/hooks/useIsMobile';
import { SESSION_STORAGE_KEYS } from '../../../shared/sessionStorageUtils';
import { parseJson } from '../../../shared/utils/jsonUtils';
import {
  getValueFromSessionStorage,
  saveObjectToSessionStorage,
  saveValueToSessionStorage,
} from '../../../shared/utils/sessionStorageUtils';
import { Container } from '../styles';
import SteelFilterInputs from './steel-filter-inputs';
import { steelResultsFilterValidationSchema } from './validation';

export interface SteelResultsFilters {
  profileShape: number | string;
  profileType: string | null;
  profileSubtype: string | null;
  sectionFactor: string | null;
  sectionType: string | null;
  orientation: number;
  protectedSides: number;
  wastage: number | 10;
  fireRating: number;
  criticalTemperature: string;
  customCriticalTemperature: string | null;
}

interface SteelResultFilterProps {
  setIsRecalculating: Dispatch<SetStateAction<boolean>>;
}

const SteelResultFilters = ({ setIsRecalculating }: SteelResultFilterProps) => {
  const isMobile = useIsMobile();
  const steelStructuralElements = parseJson(
    getValueFromSessionStorage(SESSION_STORAGE_KEYS.steelStructuralElements) ||
      ''
  );
  const steelProtectedSides = parseJson(
    getValueFromSessionStorage(SESSION_STORAGE_KEYS.steelProtectedSides) || ''
  );
  const steelEnvironment = parseJson(
    getValueFromSessionStorage(SESSION_STORAGE_KEYS.steelEnvironment) || ''
  );
  const steelFireRating = parseJson(
    getValueFromSessionStorage(SESSION_STORAGE_KEYS.steelFireRating) || ''
  );

  const sessionStorageValues: SteelResultsFilters = {
    profileShape: steelStructuralElements?.profileShape,
    profileType: steelStructuralElements?.profileType ?? '',
    profileSubtype: steelStructuralElements?.profileSubtype ?? '',
    sectionFactor: steelStructuralElements?.sectionFactor ?? '',
    sectionType: steelStructuralElements?.sectionType ?? '',
    orientation: steelStructuralElements?.orientation,
    protectedSides: steelProtectedSides,
    wastage: steelEnvironment?.wastage ?? 10,
    fireRating: steelFireRating?.fireRating ?? '',
    criticalTemperature: steelFireRating?.criticalTemperature ?? '',
    customCriticalTemperature: steelFireRating?.customCriticalTemperature ?? '',
  };

  const handleSubmit = (values: SteelResultsFilters) => {
    const steelStructuralElements = [
      {
        key: 'orientation',
        value: Number(values.orientation),
        default: '',
      },
      {
        key: 'profileShape',
        value:
          values.profileShape !== CUSTOM_PROFILE
            ? Number(values.profileShape)
            : 'Custom profile',
        default: '',
      },
    ];

    if (values.profileShape === CUSTOM_PROFILE) {
      steelStructuralElements.push(
        {
          key: 'sectionFactor',
          value: values.sectionFactor ?? '',
          default: '',
        },
        {
          key: 'sectionType',
          value: values.sectionType ?? '',
          default: '',
        }
      );
    } else {
      steelStructuralElements.push(
        {
          key: 'profileType',
          value: values.profileType ?? '',
          default: '',
        },
        {
          key: 'profileSubtype',
          value: values.profileSubtype ?? '',
          default: '',
        }
      );
    }

    saveObjectToSessionStorage(
      SESSION_STORAGE_KEYS.steelStructuralElements,
      steelStructuralElements
    );

    if (values.protectedSides) {
      saveValueToSessionStorage(SESSION_STORAGE_KEYS.steelProtectedSides, {
        value: values.protectedSides.toString(),
        default: values.protectedSides.toString(),
      });
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.steelProtectedSides);
    }

    saveObjectToSessionStorage(SESSION_STORAGE_KEYS.steelEnvironment, [
      {
        key: 'buildingFunction',
        value: steelEnvironment.buildingFunction,
        default: '',
      },
      {
        key: 'areaToProtect',
        value: steelEnvironment.areaToProtect,
        default: '',
      },
      {
        key: 'wastage',
        value: values.wastage,
        default: 10,
      },
      {
        key: 'humidity',
        value: steelEnvironment.humidity,
        default: '',
      },
      {
        key: 'temperature',
        value: steelEnvironment.temperature,
        default: '',
      },
    ]);

    const steelFireRatings = [
      {
        key: 'fireRating',
        value: Number(values.fireRating),
        default: '',
      },
      {
        key: 'criticalTemperature',
        value: values.criticalTemperature,
        default: '',
      }
    ];

    if(values.criticalTemperature === 'other') {
      steelFireRatings.push({
        key: 'customCriticalTemperature',
        value: values.customCriticalTemperature || '',
        default: '',
      });
      
    }

    saveObjectToSessionStorage(SESSION_STORAGE_KEYS.steelFireRating, steelFireRatings);
    setIsRecalculating(true);
  };

  const [areFiltersExpanded, setAreFiltersExpanded] = useState<boolean>(false);

  useEffect(() => {
    setAreFiltersExpanded(!isMobile);
  }, [isMobile]);

  return (
    <Container $isMobile={isMobile}>
      <Formik
        initialValues={sessionStorageValues}
        validationSchema={steelResultsFilterValidationSchema}
        onSubmit={(values) => handleSubmit(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {() => {
          return (
            <SteelFilterInputs
              isMobile={isMobile}
              areFiltersExpanded={areFiltersExpanded}
              setAreFiltersExpanded={setAreFiltersExpanded}
            />
          );
        }}
      </Formik>
    </Container>
  );
};

export default SteelResultFilters;
