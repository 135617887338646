import { useTranslation } from 'react-i18next';
import FormikInput from 'components/input/formik-input';
import FormikSelect from 'components/select/formik-select';
import { SteelCrossSectionalShape } from 'pages/steel/types';

interface CustomProfileInputsProps {
  steelCrossSectionalShapes?: SteelCrossSectionalShape[];
}

const CustomProfileInputs = ({
                               steelCrossSectionalShapes,
                             }: CustomProfileInputsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <FormikInput
        name="sectionFactor"
        label={`${t('steel.shape.sectionFactor')} ${t('steel.shape.sectionFactorUnits')}`}
        placeholder={t('steel.shape.sectionFactorPlaceholder') ?? ''}
        numberInput
        maxLength={3}
      />
      {steelCrossSectionalShapes && (
        <FormikSelect
          name="sectionType"
          label={t('steel.shape.sectionType') ?? ''}
          emptyValue={t('steel.shape.sectionTypeEmpty') ?? ''}
          items={steelCrossSectionalShapes}
          translationKey="steel.shape.sectionTypes"
        />
      )}
    </>
  );
};

export default CustomProfileInputs;
