import styled, { css } from 'styled-components';
import { theme } from 'styles/theme';
import EmailIcon from '../../../assets/email-icon';

export const HubspotFormWrapper = styled.div<{
  $fullWidth?: boolean;
}>`
  -ms-overflow-style: none;
  overflow-y: auto;
  scrollbar-width: none;

  & .hs-form {
    font-family: 'Avenir Next Regular', 'Avenir Next Cyr W00 Regular',
      sans-serif;
  }

  & .hs-input {
    border: 1px solid #c2d0d6;
    border-radius: 4px;
    font-family: inherit;
    font-size: 16px;
    height: 40px;
    padding-left: 8px;
    width: inherit;
  }

  & .hs-input::placeholder {
    color: grey;
  }

  & .hs-form-field label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  & .hs-form-field {
    margin-bottom: 24px;
    width: 100%;
  }

  & .hs-error-msgs .hs-error-msg,
  .hs-error-msgs .hs-main-font-element {
    color: ${theme.colors.error};
    font-weight: 400;
  }

  & .inputs-list {
    list-style-type: none;
  }

  & .hs-submit {
    display: flex;
    justify-content: flex-end;
  }

  & .hs-button {
    background-color: #0ba1e2;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 20px;
    padding: 8px 16px;
    width: 160px;
  }

  & .form-columns-0 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 24px;
    text-align: left;
  }

  & .hs-form :nth-last-child(1 of .form-columns-0) {
    color: #5c6970;
  }

  & .hs-form-booleancheckbox-display span {
    color: #5c6970;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  & .hs-form-booleancheckbox-display {
    display: flex;
  }

  & .hs-form-booleancheckbox-display .hs-input[type='checkbox'] {
    height: 20px;
    line-height: normal;
    margin-right: 8px;
    width: 20px;
  }

  & .hs-fieldtype-textarea {
    font-weight: 500;
    min-height: 100px;
    padding-top: 8px;
  }

  & a {
    color: #1482b8;
    text-decoration: underline;
  }

  & a :visited {
    color: inherit;
  }

  & .submitted-message {
    margin-bottom: 32px;
    text-align: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & div.hs-form-field div.input {
    margin-right: 0;

    & > * {
      width: 100%;
    }
  }

  & fieldset.form-columns-2 div.hs-form-field div.input input.hs-input {
    width: 100%;
  }

  ${({ $fullWidth }) => formStyles($fullWidth)};
`;

export const HubspotFormSubmittedWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledEmailIcon = styled(EmailIcon)`
  color: ${({ theme }) => theme.colors.primary};
  height: 64px;
  margin-bottom: 32px;
  width: 64px;
`;

export const ButtonWrapper = styled.div`
  height: 40px;
  margin-bottom: 24px;
  width: 160px;
    
  & > * {
    width: 100%;
  }
`;

const formStyles = (fullWidth?: boolean) => css`
  max-height: ${fullWidth ? '90vh' : '600px'};
  padding-bottom: ${fullWidth ? '132px' : '0'};
`;
