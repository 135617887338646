import styled, { css } from 'styled-components';
import { theme, typography } from '../../styles/theme';

export const Container = styled.div<{
  $isMobile: boolean;
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 12px 32px;
  text-align: center;
  ${({ $isMobile }) => restartCalculatorModalStyles($isMobile)};
`;

export const ButtonGroupWrapper = styled.div<{
  $isMobile: boolean;
}>`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  ${({ $isMobile }) => restartCalculatorModalButtonGroupStyles($isMobile)};
`;

export const ButtonWrapper = styled.div<{
  $isMobile: boolean;
}>`
  & > * {
    width: 100%;
  }

  ${({ $isMobile }) => restartCalculatorModalButtonStyles($isMobile)};
`;

export const Title = styled.h5`
  font-family: ${typography.h5};
`;

export const Content = styled.p`
  color: ${theme.colors.textPrimary};
  font-family: ${typography.body3};
`;

const restartCalculatorModalStyles = (isMobile: boolean) => css`
  align-self: ${isMobile ? 'center' : '0'};
`;

const restartCalculatorModalButtonStyles = (isMobile: boolean) => css`
  width: ${isMobile ? '100%' : '160px'};
`;

const restartCalculatorModalButtonGroupStyles = (isMobile: boolean) => css`
  flex-direction: ${isMobile ? 'column' : 'row'};
`;
