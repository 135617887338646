import { styled } from 'styled-components';
import { Body2 } from 'components/typography';
import { breakpoint, spacing } from 'styles/helpers';
import { grid } from 'styles/mixins';

export const CardsWrapper = styled.div`
  ${grid.threeColumn};
  gap: ${spacing.l};
  grid-template-rows: min-content;

  ${breakpoint.mobile} {
    gap: ${spacing.s};
  }
`;

export const NoResultsText = styled(Body2)`
  margin: ${spacing.s} 0;
`;

export const NoResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
