import useGetConcreteOrientations from '../../pages/concrete/api/useGetConcreteOrientations';
import { ConcreteOrientationName } from '../../pages/concrete/types';

const useGetConcreteStructuresIds = () => {
  const { concreteOrientations } = useGetConcreteOrientations();
  const beamOrientationId = concreteOrientations?.find(
    (orientation) => orientation.name === ConcreteOrientationName.Beam
  )?.id;
  const columnOrientationId = concreteOrientations?.find(
    (orientation) => orientation.name === ConcreteOrientationName.Column
  )?.id;

  return {beamOrientationId, columnOrientationId};
};

export default useGetConcreteStructuresIds;