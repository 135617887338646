import { SESSION_STORAGE_KEYS } from '../sessionStorageUtils';
import {
  getValueFromSessionStorage,
  saveObjectToSessionStorage,
  saveValueToSessionStorage,
} from './sessionStorageUtils';

const enum STRUCTURE {
  STEEL = 'Steel',
  CONCRETE = 'Concrete',
}

export const parseQueryParameters = (url: URL) => {
  const queryParams = Object.fromEntries(url.searchParams.entries());

  if (!queryParams) {
    return;
  }

  if (queryParams.codeCountry && queryParams.nameCountry) {
    saveObjectToSessionStorage(SESSION_STORAGE_KEYS.country, [
      {
        key: 'code',
        value: queryParams.codeCountry,
        default: null,
      },
      {
        key: 'name',
        value: queryParams.nameCountry,
        default: null,
      },
      {
        key: 'default',
        value: false,
        default: false,
      },
    ]);
  }

  if(!getValueFromSessionStorage(SESSION_STORAGE_KEYS.product))
  {
    saveObjectToSessionStorage(SESSION_STORAGE_KEYS.product, [
      {
        key: 'id',
        value: queryParams.id,
        default: queryParams.productId || null,
      },
      {
        key: 'category',
        value: queryParams.category,
        default: null,
      },
    ]);
  }

  if (queryParams?.structure && queryParams?.structure === STRUCTURE.STEEL) {
    saveValueToSessionStorage(SESSION_STORAGE_KEYS.structure, {
      value: queryParams.structure,
      default: '',
    });

    if (queryParams.profileShape !== 'Custom profile') {
      saveObjectToSessionStorage(SESSION_STORAGE_KEYS.steelStructuralElements, [
        {
          key: 'profileShape',
          value: parseInt(queryParams.profileShape),
          default: queryParams.profileShape,
        },
        {
          key: 'orientation',
          value: parseInt(queryParams.orientation),
          default: '',
        },
        {
          key: 'profileType',
          value: queryParams.profileType,
          default: '',
        },
        {
          key: 'profileSubtype',
          value: queryParams.profileSubtype,
          default: '',
        },
      ]);
    } else {
      saveObjectToSessionStorage(SESSION_STORAGE_KEYS.steelStructuralElements, [
        {
          key: 'profileShape',
          value: parseInt(queryParams.profileShape),
          default: queryParams.profileShape,
        },
        {
          key: 'orientation',
          value: parseInt(queryParams.orientation),
          default: '',
        },
        {
          key: 'sectionFactor',
          value: queryParams.sectionFactor,
          default: '',
        },
        {
          key: 'sectionType',
          value: queryParams.sectionType,
          default: '',
        },
      ]);
    }

    if (queryParams.protectedSideId || queryParams.steelProtectedSides) {
      saveValueToSessionStorage(SESSION_STORAGE_KEYS.steelProtectedSides, {
        value: queryParams.protectedSideId,
        default: queryParams.steelProtectedSides,
      });
    }

    if (queryParams.id && queryParams.category) {
      saveObjectToSessionStorage(SESSION_STORAGE_KEYS.steelEnvironment, [
        {
          key: 'buildingFunction',
          value: queryParams.buildingFunction,
          default: '',
        },
        {
          key: 'areaToProtect',
          value: queryParams.areaToProtect,
          default: '',
        },
        {
          key: 'wastage',
          value: queryParams.wastage,
          default: 10,
        },
      ]);
    } else {
      saveObjectToSessionStorage(SESSION_STORAGE_KEYS.steelEnvironment, [
        {
          key: 'buildingFunction',
          value: queryParams.buildingFunction,
          default: '',
        },
        {
          key: 'areaToProtect',
          value: queryParams.areaToProtect,
          default: '',
        },
        {
          key: 'wastage',
          value: queryParams.wastage,
          default: 10,
        },
        {
          key: 'humidity',
          value: queryParams.humidity,
          default: '',
        },
        {
          key: 'temperature',
          value: queryParams.temperature,
          default: '',
        },
      ]);
    }

    if (queryParams?.fireRating && queryParams?.criticalTemperature)
      saveObjectToSessionStorage(SESSION_STORAGE_KEYS.steelFireRating, [
        {
          key: 'fireRating',
          value: parseInt(queryParams.fireRating),
          default: '',
        },
        {
          key: 'criticalTemperature',
          value: queryParams.criticalTemperature,
          default: '',
        },
      ]);
  }

  if (queryParams?.structure && queryParams?.structure === STRUCTURE.CONCRETE) {
    saveValueToSessionStorage(SESSION_STORAGE_KEYS.structure, {
      value: queryParams.structure,
      default: '',
    });

    saveObjectToSessionStorage(SESSION_STORAGE_KEYS.concreteStructuralElements, [
      {
        key: 'orientation',
        value: parseInt(queryParams.orientation),
        default: '',
      },
      {
        key: 'depth',
        value: queryParams.depth,
        default: '',
      },
      {
        key: 'width',
        value: queryParams.width,
        default: '',
      },
      {
        key: 'requiredAxis',
        value: queryParams.requiredAxis,
        default: '',
      },
      {
        key: 'currentAxis',
        value: queryParams.currentAxis,
        default: '',
      },
    ]);

    if (queryParams.id && queryParams.category) {
      saveObjectToSessionStorage(SESSION_STORAGE_KEYS.concreteEnvironment, [
        {
          key: 'buildingFunction',
          value: queryParams.buildingFunction,
          default: '',
        },
        {
          key: 'areaToProtect',
          value: queryParams.areaToProtect,
          default: '',
        },
        {
          key: 'wastage',
          value: queryParams.wastage,
          default: 10,
        },
      ]);
    } else {
      saveObjectToSessionStorage(SESSION_STORAGE_KEYS.concreteEnvironment, [
        {
          key: 'buildingFunction',
          value: queryParams.buildingFunction,
          default: '',
        },
        {
          key: 'areaToProtect',
          value: queryParams.areaToProtect,
          default: '',
        },
        {
          key: 'wastage',
          value: queryParams.wastage,
          default: 10,
        },
        {
          key: 'humidity',
          value: queryParams.humidity,
          default: '',
        },
        {
          key: 'temperature',
          value: queryParams.temperature,
          default: '',
        },
      ]);
    }

    if (queryParams?.concreteFireRating)
      saveValueToSessionStorage(SESSION_STORAGE_KEYS.concreteFireRating, {
        value: queryParams.concreteFireRating,
        default: '',
      });
  }
};
