import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useGetProductDetails from 'api/useGetProductDetails';
import Carousel from 'components/carousel';
import Loader from 'components/loader';
import Tabs from 'components/tabs';
import ResultsRestartButton from 'features/results-restart-button';
import TopActions from 'features/top-actions';
import useIsMobile from 'shared/hooks/useIsMobile';
import useRestartCalculator from 'shared/hooks/useRestartCalculator';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import { CalculationResultData, InputDetailsData } from 'shared/types';
import { ButtonVariant } from '../../components/button/types';
import ContactSupportCtaButton from '../../components/contact-support-cta-button';
import ModalComponent from '../../components/modal';
import HubspotForm from '../../components/modal/modal-form';
import ModalHeader from '../../components/modal/modal-header';
import RequestSpecificationsCtaButton from '../../components/request-specifications-cta-button';
import { HS_FORM_PORTAL, HS_FORM_REGION } from '../../shared/constants';
import CalculationDetails from './components/calculation-details';
import DownloadPdf from './components/download-pdf';
import InputDetails from './components/input-details';
import InstallationDetails from './components/installation-details';
import ProductDescription from './components/product-description';
import { getProductDetailsTabs, getTabContent } from './logic';
import {
  Wrapper,
  ContentLeft,
  Heading,
  MobileWrapper,
  TabContentWrapper,
  ContentRight,
  ProductNotTestedText,
  CalculationDetailsWrapper,
  ButtonWrapper, ProductNotTestedWrapper,
} from './styles';
import { ProductDetailsSection } from './types';

interface ProductDetailsProps {
  calculationResult?: CalculationResultData;
  inputDetails: InputDetailsData;
  isCalculationResultLoading?: boolean;
  backRoute: string;
}

const ProductDetails = ({
                          calculationResult,
                          inputDetails,
                          isCalculationResultLoading,
                          backRoute,
                        }: ProductDetailsProps) => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const tabs = getProductDetailsTabs(t, calculationResult);
  const [selectedSection, setSelectedSection] = useState(
    ProductDetailsSection.calculationResult,
  );
  const { productDetails, isLoading: isProductDetailsLoading } =
    useGetProductDetails(productId);

  const restartCalculator = useRestartCalculator();

  const isLoading = isProductDetailsLoading || isCalculationResultLoading;

  const selectProductDetailsSection = (section: string) =>
    setSelectedSection(section as ProductDetailsSection);

  const handleBackClick = () => {
    removeFromSession(SESSION_STORAGE_KEYS.inputParametersFromResults);
    removeFromSession(SESSION_STORAGE_KEYS.product);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.product, JSON.stringify({ 'id': null, 'category': null }));
    navigate(backRoute);
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState({
    title: '',
    portalId: '',
    formId: '',
    region: '',
  });

  const onContactSupportModalOpen = () => {
    setModalData({
      title: t('modal.contactSupport'),
      portalId: HS_FORM_PORTAL,
      formId: t('hubspot.contactSupportFormId'),
      region: HS_FORM_REGION,
    });

    setIsModalOpen(true);
  };

  const onRequestModalOpen = () => {
    setModalData({
      title: t('modal.requestSpecification'),
      portalId: HS_FORM_PORTAL,
      formId: t('hubspot.requestSpecificationsFormId'),
      region: HS_FORM_REGION,
    });

    setIsModalOpen(true);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <ModalComponent
        header={
          <ModalHeader
            title={modalData.title}
            handleClose={() => {
              setIsModalOpen(false);
            }}
          />
        }
        isOpen={isModalOpen}
        content={
          <HubspotForm
            portalId={modalData.portalId}
            formId={modalData.formId}
            region={modalData.region}
            onFormSubmitted={() =>
              setModalData((prevState) => ({ ...prevState, title: '' }))
            }
            onDoneClick={() => {
              setIsModalOpen(false);
            }}
          />
        }
      />

      <TopActions
        onBackClick={handleBackClick}
        title={productDetails?.name}
        rightAction={<ResultsRestartButton onClick={restartCalculator} />}
      />
      {isMobile ? (
        <MobileWrapper>
          {calculationResult ? (
            <>
              <Carousel items={productDetails?.imageIds} />
              <Tabs
                items={tabs}
                onChange={selectProductDetailsSection}
                fullWidth
              />
              <TabContentWrapper>
                {getTabContent(
                  selectedSection,
                  onContactSupportModalOpen,
                  onRequestModalOpen,
                  productDetails,
                  calculationResult,
                  inputDetails,
                )}
              </TabContentWrapper>
            </>
          ) : (
            <ProductNotTestedWrapper>
              <ProductNotTestedText>
                {t('productDetails.productNotTested')}
              </ProductNotTestedText>
              <ContactSupportCtaButton
                onClick={onContactSupportModalOpen}
                variant={ButtonVariant.outlined}
              />
            </ProductNotTestedWrapper>
          )}
        </MobileWrapper>
      ) : (
        <>
          <Heading>{productDetails?.name}</Heading>
          {calculationResult ? (
            <Wrapper>
              <ContentLeft>
                <Carousel items={productDetails?.imageIds} />
                <ProductDescription productDetails={productDetails} />
              </ContentLeft>
              <ContentRight>
                {calculationResult && (
                  <CalculationDetailsWrapper>
                    <CalculationDetails calculationResult={calculationResult} />
                    <ButtonWrapper>
                      <DownloadPdf
                        calculationResult={calculationResult}
                        productDetails={productDetails}
                        inputDetails={inputDetails}
                      />
                      <ContactSupportCtaButton
                        onClick={() => {
                          onContactSupportModalOpen();
                        }}
                        variant={ButtonVariant.outlined}
                      />
                      <RequestSpecificationsCtaButton
                        onClick={() => {
                          onRequestModalOpen();
                        }}
                        variant={ButtonVariant.outlined}
                      />
                    </ButtonWrapper>
                  </CalculationDetailsWrapper>
                )}
                <InputDetails inputDetails={inputDetails} />
                <InstallationDetails
                  productDetails={productDetails}
                  testReportNo={calculationResult.testReportNo}
                />
              </ContentRight>
            </Wrapper>
          ) : (
            <ProductNotTestedWrapper>
              <ProductNotTestedText>
                {t('productDetails.productNotTested')}
              </ProductNotTestedText>
              <ContactSupportCtaButton
                onClick={onContactSupportModalOpen}
                variant={ButtonVariant.outlined}
              />
            </ProductNotTestedWrapper>
          )}
        </>
      )}
    </div>
  );
};
export default ProductDetails;
