import { parseObjectToJson } from './jsonUtils';

export const getValueFromSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

interface ValueTemplate {
  value: string;
  default: string;
}

export const saveValueToSessionStorage = (
  key: string,
  value: ValueTemplate
): void => {
  sessionStorage.setItem(key, value.value || value.default);
};

interface ObjectTemplate {
  key: string;
  value: string | number | null | boolean;
  default: string | number | null | boolean;
}

export const saveObjectToSessionStorage = (
  sessionObjectKeyName: string,
  values: ObjectTemplate[]
) => {
  const finalObject: { [key: string]: string | number | null | boolean } = {};
  values.forEach((entry) => {
    if (entry) {
      finalObject[entry.key] = entry ? entry.value || entry.default : null;
    }
  });
  sessionStorage.setItem(sessionObjectKeyName, parseObjectToJson(finalObject));
};