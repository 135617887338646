import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import useGetSteelCriticalTemperatures from '../../../../pages/steel/api/useGetSteelCriticalTemperatures';
import useGetSteelCrossSectionalShapes from '../../../../pages/steel/api/useGetSteelCrossSectionalShapes';
import useGetSteelFireRatings from '../../../../pages/steel/api/useGetSteelFireRatings';
import useGetSteelOrientations from '../../../../pages/steel/api/useGetSteelOrientations';
import useGetSteelProfileShapes from '../../../../pages/steel/api/useGetSteelProfileShapes';
import useGetSteelProfileTypesSubtypes from '../../../../pages/steel/api/useGetSteelProfileTypesSubtypes';
import useGetSteelProtectedSides from '../../../../pages/steel/api/useGetSteelProtectedSides';
import { CUSTOM_PROFILE } from '../../../../pages/steel/constants';
import { filterProtectedSides } from '../../../../pages/steel/protected-sides/logic';
import {
  getProfileSubtypeSelectOptions,
  getProfileTypeSelectOptions,
} from '../../../../pages/steel/structural-elements/components/standard-profile-inputs/logic';
import FormikInput from '../../../input/formik-input';
import Loader from '../../../loader';
import FormikSelect from '../../../select/formik-select';
import { SelectItem } from '../../../select/types';
import FilterButtons from '../../components/filterButtons';
import { FilterInputsProps } from '../../constants';
import {
  ShowHideFiltersMobile,
  StyledArrowIcon,
  StyledForm,
} from '../../styles';
import { SteelResultsFilters } from '../index';
import useDataMappingWithUnits from '../../../../shared/hooks/useDataMappingWithUnits';

const SteelFilterInputs = ({
  isMobile,
  areFiltersExpanded,
  setAreFiltersExpanded,
}: FilterInputsProps) => {
  const { t } = useTranslation();
  const { values: steelValues, setFieldValue } = useFormikContext<SteelResultsFilters>();
  const {mapTemperaturesWithUnits, mapFireRatingsWithUnits, mapProtectedSidesWithLabels} = useDataMappingWithUnits();
  const customProfile = steelValues.profileShape === CUSTOM_PROFILE;

  const [isMounted, setIsMounted] = useState(false);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [profileShape, setProfileShape] = useState<number>(Number(steelValues.profileShape));

  const { steelProfileShapes, isLoading: isLoadingProfileShapes } = useGetSteelProfileShapes();

  const profiles = steelProfileShapes?.map((shape) => {
    return {
      id: shape.id,
      name: t(`steel.shape.${shape.name}`),
    };
  });

  const { steelOrientations, isLoading: isLoadingOrientations } =
    useGetSteelOrientations();

  const profileShapeSelectItems = profiles ?? [];
  profileShapeSelectItems.push({
    id: CUSTOM_PROFILE,
    name: CUSTOM_PROFILE,
  });

  const profileOrientationSelectItems = steelOrientations?.map(e => {
    return {
      id: e.id,
      name: t(`steel.orientation.${e.name}`),
    };
  }) ?? [];

  const {
    steelCrossSectionalShapes,
    isLoading: isLoadingCrossSectionalShapes,
  } = useGetSteelCrossSectionalShapes();

  const steelCrossSectionalShapesSelectItems = steelCrossSectionalShapes ?? [];

  const {
    steelProfileTypesSubtypes,
    isLoading: isLoadingProfileTypesSubtypes,
  } = useGetSteelProfileTypesSubtypes(profileShape);

  const profileTypes = getProfileTypeSelectOptions(steelProfileTypesSubtypes);
  const steelProfileTypesSelectItems = profileTypes ?? [];

  const [profileSubtypes, setProfileSubtypes] = useState<SelectItem[]>();

  const { fireRatings, isLoading: isLoadingFireRatings } =
    useGetSteelFireRatings();

  const fireRatingsWithUnits = mapFireRatingsWithUnits(fireRatings ?? []);

  const { criticalTemperatures, isLoading: isLoadingCriticalTemperatures } =
    useGetSteelCriticalTemperatures();

  const criticalTemperaturesWithUnits = mapTemperaturesWithUnits(criticalTemperatures ?? []);

  const fireRatingsSelectItems = fireRatingsWithUnits ?? [];
  const criticalTemperaturesSelectItems = criticalTemperaturesWithUnits ?? [];
  criticalTemperaturesSelectItems.push({id: 'other', name: t('steel.fireRating.temperature.Other')});

  const { steelProtectedSides, isLoading: isLoadingProtectedSides } =
    useGetSteelProtectedSides({
      profileShape: steelValues.profileShape,
      orientation: steelValues.orientation,
      profileType: steelValues.profileType,
      profileSubtype: steelValues.profileSubtype,
    });

  const protectedSides = filterProtectedSides(steelProtectedSides ?? []);
  const protectedSidesSelectItems = [...mapProtectedSidesWithLabels(protectedSides ?? [])];

  useEffect(() => {
    if (profileTypes?.length === 1 && steelValues.profileType !== String(profileTypes[0].id))
    {
      setFieldValue('profileType', String(profileTypes[0].id));
    }

    const profileSubtypes = getProfileSubtypeSelectOptions(
      steelValues.profileType || '',
      steelProfileTypesSubtypes
    );

    setProfileSubtypes(profileSubtypes);
  }, [profileTypes, steelValues.profileType]);


  useEffect(() => {
    if (isMounted) {
      if (steelValues.profileShape && !customProfile) {
        setProfileShape(Number(steelValues.profileShape));
      }
      setFieldValue('profileType', null);
      setFieldValue('profileSubtype', null);
      setFieldValue('sectionType', null);
      setFieldValue('protectedSides', null);
      setProfileSubtypes([]);
    } else {
      setIsMounted(true);
    }
  }, [steelValues.profileShape]);

  useEffect(() => {
    if (isMounted) {
      setFieldValue('profileSubtype', null);
      setFieldValue('protectedSides', null);
      setProfileSubtypes([]);
    } else {
      setIsMounted(true);
    }
  }, [steelValues.profileType]);

  useEffect(() => {
    if (isMounted) {
      setFieldValue('customCriticalTemperature', null);
    } else {
      setIsMounted(true);
    }
  }, [steelValues.criticalTemperature]);

  const handleShowHideFiltersClick = () => {
    setShowAllFilters(!showAllFilters);
  };

  const isLoading =
    isLoadingProfileShapes ||
    isLoadingOrientations ||
    isLoadingCrossSectionalShapes ||
    isLoadingProfileTypesSubtypes ||
    isLoadingFireRatings ||
    isLoadingCriticalTemperatures ||
    isLoadingProtectedSides;

  if (isLoading) {
    return <Loader fullScreen={false}/>;
  }

  return (
    <>
      {isMobile && (
        <ShowHideFiltersMobile
          onClick={() => setAreFiltersExpanded(!areFiltersExpanded)}
        >
          {t('results.filters.filters')}
          <StyledArrowIcon $rotate_down={areFiltersExpanded} />
        </ShowHideFiltersMobile>
      )}

      {areFiltersExpanded && (
        <StyledForm $isMobile={isMobile}>
          <FormikSelect
            name="profileShape"
            label={t('results.filters.steel.profileShape') ?? ''}
            items={profileShapeSelectItems}
            small
          />

          { customProfile ? (
            <>
              <FormikInput
                name="sectionFactor"
                label={t('results.filters.steel.sectionFactor') ?? ''}
                placeholder={t('results.filters.steel.sectionFactor') ?? ''}
                numberInput
                decimal
                small
              />

              <FormikSelect
                name="sectionType"
                label={t('results.filters.steel.sectionType') ?? ''}
                items={steelCrossSectionalShapesSelectItems}
                small
              />
            </>
          ) : (
            <>
               <FormikSelect
                name="profileType"
                label={t('results.filters.steel.profileType') ?? ''}
                items={steelProfileTypesSelectItems}
                translationKey={
                  steelProfileTypesSelectItems.length === 1
                    ? 'steel.shape.hollowShapesPlaceholders'
                    : undefined
                }
                small
              />

              { profileSubtypes && <FormikSelect
                  name="profileSubtype"
                  label={t('results.filters.steel.profileSubtype') ?? ''}
                  items={profileSubtypes || []}
                  small
                />
              }
            </>
          )}

          <FormikSelect
            name="orientation"
            label={t('results.filters.steel.orientation') ?? ''}
            items={profileOrientationSelectItems}
            small
          />

          {!customProfile && (
            <FormikSelect
              name="protectedSides"
              label={t('results.filters.steel.protectedSides') ?? ''}
              items={protectedSidesSelectItems}
              small
            />
          )}

          <FormikSelect
            name="criticalTemperature"
            label={t('results.filters.steel.criticalTemperature') ?? ''}
            items={criticalTemperaturesSelectItems}
            small
          />

          {
            steelValues.criticalTemperature === 'other' &&
            <FormikInput
              name="customCriticalTemperature"
              label={t('results.filters.steel.customCriticalTemperature') ?? ''}
              placeholder={t('steel.fireRating.criticalTemperaturePlaceholder') ?? ''}
              numberInput
              decimal
              small
              maxValue={750}
            />
          }

          <FormikSelect
            name="fireRating"
            label={t('results.filters.steel.fireRating') ?? ''}
            items={fireRatingsSelectItems}
            small
          />
          {showAllFilters && (
            <FormikInput
              name="wastage"
              label={t('results.filters.steel.wastage') ?? ''}
              placeholder={t('results.filters.steel.wastage') ?? ''}
              numberInput
              decimal
              maxValue={50}
              small
            />
          )}
          <FilterButtons
            filtersExpanded={showAllFilters}
            onShowHide={handleShowHideFiltersClick}
          />
        </StyledForm>
      )}
    </>
  );
};

export default SteelFilterInputs;
