import styled from 'styled-components';
import { spacing } from '../../../styles/helpers';
import { theme } from '../../../styles/theme';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['s']};

  & > * {
    width: 100%;
  }
`;

export const ShowHideFiltersButtonWrapper = styled.div`
  & > * {
    color: ${theme.colors.textPrimary};
    width: 100%;
  }
`;
