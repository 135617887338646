import styled from 'styled-components';
import { spacing, sizing, radii } from 'styles/helpers';
import { iconSizing, rotate } from 'styles/mixins';
import { theme } from 'styles/theme';

export const NumberInputButtonsWrapper = styled.div`
  border-left: ${({ theme }) => theme.border.inactive};
  display: flex;
  flex-direction: column;
  width: ${sizing(6)};

  > * {
    height: ${sizing(6)};

    svg {
      color: ${({ theme }) => theme.colors.primary};
      ${iconSizing.default}
    }
  }

  button:first-child {
    border-bottom: ${({ theme }) => theme.border.inactive};
    margin-bottom: -1px;

    svg {
      ${rotate(180)}
    }
  }
`;

export const inputStyles = (small: boolean) => {
  return {
    alignItems: 'center',
    border: theme.border.inactive,
    borderRadius: radii.s,
    display: 'flex',
    fontFamily: theme.fontFamily.regular,
    fontSize: theme.fontSize.s,
    height: small ? sizing(8) : sizing(12),
    padding: 0,

    '&::before, &::after, &.MuiInput-underline:hover:before': {
      border: 0,
    },

    '&.Mui-focused, &:hover': {
      border: theme.border.primary,
    },

    '& ::placeholder': {
      color: theme.colors.textDisabled,
      opacity: 1,
    },

    '& .MuiInput-input': {
      boxSizing: 'border-box',
      height: '100%',
      padding: small ? `10px ${spacing.s}` : spacing.s,
    },

    '&.Mui-error': {
      border: theme.border.error,
    },

    //hide default number input buttons
    'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        WebkitAppearance: 'none',
        margin: 0,
      },

    //hide default number input buttons on firefox
    'input[type=number]': {
      MozAppearance: 'textfield',
    },
  };
};

export const InfoWrapper = styled.p`
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
  text-align: left;
`;
