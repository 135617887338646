import styled from 'styled-components';
import { Form } from 'formik';
import { spacing } from '../../styles/helpers';
import { typography } from '../../styles/theme';
import ArrowIcon from '../../assets/arrow-icon';

export const Container = styled.div<{ $isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    min-width: 280px;
    width: ${props => props.$isMobile ? '100%' : 'fit-content'};
`;

export const StyledForm = styled(Form)<{ $isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: ${props => props.$isMobile ? '48px' : '0'};
    padding-bottom: 16px;
`;

export const StyledArrowIcon = styled(ArrowIcon)<{ $rotate_down: boolean}>`
    color: ${({ theme }) => theme.colors.primary};
    height: 20px;
    transform: ${props => (props.$rotate_down ? 'rotate(-180deg)' : 'rotate(0deg)')};
    width: 20px;
`;

export const ShowHideFiltersMobile = styled.div`
    align-items: center;
    display: flex;
    font: ${typography.h7};
    gap: 8px;
    justify-content: center;
    margin-bottom: ${spacing.s};
    width: 100%;
`;


