import { ReactNode } from 'react';

export type TabItem = {
  value: string;
  label: string;
};

export type Product = {
  category: string;
  categoryType: string;
  id: string;
  name: string;
  previewImageId: string;
  shortDescription: string;
};

export enum CalculationResultType {
  Board = 'board',
  Spray = 'spray',
  Paint = 'paint',
}

export type BoardsCalculationResult = {
  testReportNo?: string;
  $type?: CalculationResultType;
  boardLayers?: {
    value: number[];
    unit: string;
  };
  boardThickness: ValueUnitPair;
  estimatedInstallationTime?: ValueUnitPair;
  totalAreaToProtect?: ValueUnitPair;
  wastage: ValueUnitPair;
};

export type SpraysCalculationResult = {
  testReportNo?: string;
  $type?: CalculationResultType;
  totalAreaToProtect?: ValueUnitPair;
  sprayThickness: ValueUnitPair;
  estimatedApplicationTime?: ValueUnitPair;
  numberOfCoats?: ValueUnitPair;
  amountNeededPerM2?: ValueUnitPair;
  amountNeededTotal?: ValueUnitPair;
  wastage: ValueUnitPair;
};

export type PaintsCalculationResult = {
  testReportNo?: string;
  $type?: CalculationResultType;
  totalAreaToProtect?: ValueUnitPair;
  dryFilmThickness: ValueUnitPair;
  estimatedApplicationTime?: ValueUnitPair;
  numberOfCoats?: ValueUnitPair;
  amountNeededPerM2?: ValueUnitPair;
  amountNeededTotal?: ValueUnitPair;
  wastage: ValueUnitPair;
};

export type CalculationResultData =
  | BoardsCalculationResult
  | PaintsCalculationResult
  | SpraysCalculationResult;

export type ProductDetailsData = {
  name: string;
  description: string;
  shortDescription: string;
  benefits: string;
  installationDetails: { [key: string]: string };
  imageIds: string[];
};

export type CalculationResultsItem = {
  calculationResult: CalculationResultData;
  productDetails: Product;
  calculationInputParameters : CalculationInputParamsData;
};

export enum ProductCategory {
  Boards = 'PromatConstructionFireProtectionBoards',
  Sprays = 'PromatConstructionFireProtectionSprays',
  Paints = 'PromatConstructionIntumescentPaints',
}

export enum LoadbearingStructure {
  steel = 'Steel',
  concrete = 'Concrete',
  timber = 'Timber',
}

export type Country = {
  code: string;
  flagIconSvg: string;
  name: string;
};

export type Language = {
  code: string;
  name: string;
};

export type FireRating = {
  id: number;
  value: number;
  isMainOption: boolean;
};

export enum ImageRendition {
  Preview = 'Preview',
  Thumbnail = 'Thumbnail',
  Smallthumbnail = 'Smallthumbnail',
  Tinythumbnail = 'Tinythumbnail',
  Original = 'Original',
}

export enum ProgressStep {
  structure = 'structure',
  product = 'product',
  structuralElements = 'structuralElements',
  protectedSides = 'protectedSides',
  environment = 'environment',
  fireRating = 'fireRating',
  result = 'result',
}

export type SvgIconSet = {
  [key: string]: ReactNode;
};

export type IdNamePair = {
  id: number;
  name: string;
};

export type IdNamePairWithStringId = {
  id: string;
  name: string;
};

export type ValueUnitPair = {
  value: number;
  unit: string;
};

export type NameValuePair = {
  name: string;
  value: string;
};

export type ProductWithCalculationDetails = {
  calculationResult: NameValuePair[];
  productDetails: Product;
  calculationInputParameters : CalculationInputParamsData
};

export type ProductsCount = {
  structureType: string;
  count: number;
};

export type SteelCalculationInputParamsData = {
  productId: number
  category: string
  fireRatingId: number
  orientationId: number
  totalAreaToProtectM2: number
  criticalTemperatureId: number
  criticalTemperatureC: number
  crossSectionalShapeId: number
  sectionFactor: number
  profileSubTypeId: number
  protectedSideId: number
}

export type ConcreteCalculationInputParamsData = {
  productId: number
  category: string
  fireRatingId: number
  orientationId: number
  totalAreaToProtectM2: number
  requiredAxisDistance: number
  currentAxisDistance: number
}

export type CalculationInputParamsData = SteelCalculationInputParamsData | ConcreteCalculationInputParamsData

export type ConcreteInputDetailsData = {
  orientationName: string;
  fireRatingId: number;
  currentAxisDistance: ValueUnitPair;
  requiredAxisDistance: ValueUnitPair;
  depth: ValueUnitPair;
  width: ValueUnitPair;
  temperature?: string;
  humidity?: string;
  buildingFunction?: string;
};

export type SteelInputDetailsData = {
  profileTypeName: string;
  profileSubTypeName: string;
  sectionFactor: number;
  crossSectionalShapeName: string;
  sidesToProtect: number;
  orientationName: string;
  fireRatingValue: number;
  criticalTemperature: ValueUnitPair;
  temperature?: string;
  humidity?: string;
  buildingFunction?: string;
};

export type InputDetailsData = ConcreteInputDetailsData | SteelInputDetailsData;

export enum ProtectedSidesType {
  Box = 'Box',
  Contour = 'Contour',
  Any = 'Any',
}
