import { styled } from 'styled-components';
import { spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

export const Container = styled.div`
  background-color: #eaf6fe;
  border-radius: 4px;
  column-gap: ${spacing.xs};
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto auto;
  height: fit-content;
  padding: ${spacing.s};

  & > * {
    padding-bottom: 4px;
  }
`;

export const DisclaimerTitleWrapper = styled.div`
  font-size: ${theme.typography.h7};
  grid-column: 2 / 3;
  padding-top: 4px;
`;

export const DisclaimerTitleIconWrapper = styled.div`
  color: ${theme.colors.primary};
  grid-column: 1 / 2;

  & > * {
    height: 24px;
    width: 24px;
  }
`;
