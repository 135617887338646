import { useTranslation } from 'react-i18next';
import useIsMobile from '../../shared/hooks/useIsMobile';
import Button from '../button';
import { ButtonVariant } from '../button/types';
import ModalComponent from '../modal';
import ModalHeader from '../modal/modal-header';
import {
  ButtonGroupWrapper,
  ButtonWrapper,
  Container,
  Content,
  Title,
} from './styles';

interface RestartCalculatorModalContentProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const RestartCalculatorModal = ({
  handleClose,
  handleConfirm,
  isOpen,
}: RestartCalculatorModalContentProps) => {

  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <ModalComponent
      header={<ModalHeader handleClose={handleClose} />}
      content={
        <Container $isMobile={isMobile}>
          <Title>{t('restartCalculatorModal.header')}</Title>
          <Content>{t('restartCalculatorModal.message')}</Content>
          <ButtonGroupWrapper $isMobile={isMobile}>
            <ButtonWrapper $isMobile={isMobile}>
              <Button
                content={t('restartCalculatorModal.cancel')}
                variant={ButtonVariant.outlined}
                onClick={handleClose}
              />
            </ButtonWrapper>
            <ButtonWrapper $isMobile={isMobile}>
              <Button
                content={t('restartCalculatorModal.confirm')}
                variant={ButtonVariant.filled}
                onClick={handleConfirm}
              />
            </ButtonWrapper>
          </ButtonGroupWrapper>
        </Container>
      }
      isOpen={isOpen}
    />
  );
};

export default RestartCalculatorModal;
