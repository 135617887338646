import * as Yup from 'yup';
import { CUSTOM_PROFILE } from '../../../pages/steel/constants';
import { TEMPERATURE_OTHER } from '../../../pages/steel/fire-rating/constants';

export const steelResultsFilterValidationSchema = Yup.object().shape({
  profileShape: Yup.string().required('results.filters.required'),
  orientation: Yup.string().required('results.filters.required'),
  profileType: Yup.string().when('profileShape', {
    is: (value: string) => value !== CUSTOM_PROFILE,
    then: (schema) => schema.required('results.filters.required'),
    otherwise: (schema) => schema.notRequired()
  }),
  profileSubtype: Yup.string().when('profileShape', {
    is: (value: string) => value !== CUSTOM_PROFILE,
    then: (schema) => schema.required('results.filters.required'),
    otherwise: (schema) => schema.notRequired()
  }),
  fireRating: Yup.string().required('fireRating.error'),
  criticalTemperature: Yup.string().required(
    'steel.errors.criticalTemperature'
  ),
  customCriticalTemperature: Yup.number().when('criticalTemperature', {
    is: TEMPERATURE_OTHER.id,
    then: (schema) => schema.required('steel.errors.criticalTemperature'),
    otherwise: (schema) => schema.notRequired()
  }).typeError('steel.errors.criticalTemperature')
    .min(350, 'steel.errors.criticalTemperature')
    .max(750, 'steel.errors.criticalTemperature'),
  wastage: Yup.number().required('environment.errors.wastagePercentage')
    .typeError('environment.errors.wastagePercentage')
    .min(0, 'environment.errors.wastagePercentage')
    .max(50, 'environment.errors.wastagePercentage'),
  protectedSides: Yup.number().when('profileShape', {
    is: (value: string) => value !== CUSTOM_PROFILE,
    then: (schema) => schema.required('results.filters.required'),
    otherwise: (schema) => schema.notRequired()
  }),
  sectionFactor: Yup.number()
    .min(0, 'steel.errors.sectionFactor')
    .max(500, 'steel.errors.sectionFactor')
    .when('profileShape', {
      is: (value: string) => value === CUSTOM_PROFILE,
      then: (schema) => schema.required('steel.errors.sectionFactor'),
      otherwise: (schema) => schema.notRequired()
    }),
  sectionType: Yup.string().when('profileShape', {
    is: (value: string) => value === CUSTOM_PROFILE,
    then: (schema) => schema.required('results.filters.required'),
    otherwise: (schema) => schema.notRequired()
  }),
});


