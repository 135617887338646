import i18next, { TFunction } from 'i18next';
import { InputDetailsData, ValueUnitPair } from 'shared/types';

const filterInputDetails = (inputDetails: InputDetailsData) =>
  Object.fromEntries(
    Object.entries(inputDetails).filter(([, value]) => value !== null)
  );

const isValueUnitPair = (
  obj: ValueUnitPair | string | number
): obj is ValueUnitPair => (obj as ValueUnitPair).value !== undefined;

const mapInputDetailsValues = (
  t: TFunction,
  detailKey: string,
  value: string | number
) => {
  switch (detailKey) {
    case 'fireRatingValue':
      return t('fireRating.time', {
        amount: value,
      });
    case 'sidesToProtect':
      return String(
        Number(value) > 1
          ? t('steel.protectedSides.label.sides', {
              count: Number(value),
            })
          : t('steel.protectedSides.label.side', {
              count: Number(value),
            })
      );
    case 'profileTypeName':
      if (value === 'customProfile') {
        return t('productDetails.customProfile');
      }
      else if(i18next.exists(`steel.shape.hollowShapesPlaceholders.${value}`)) {
        return t(`steel.shape.hollowShapesPlaceholders.${value}`);
      }
      return value;
    case 'steelOrientationName':
      return t(`steel.orientation.${value}`);
    case 'crossSectionalShapeName':
      return t(`steel.shape.sectionTypes.${value}`);
    case 'concreteOrientationName':
      return t(`concrete.structuralElements.${value}`);
    case 'sectionFactor':
      return `${value} ${t('productDetails.sectionFactorUnits')}`;
    default:
      return value;
  }
};

const mapInputDetails = (t: TFunction, inputDetails?: InputDetailsData) => {
  if (!inputDetails) {
    return [];
  }

  const isConcrete =
    'requiredAxisDistance' in inputDetails && inputDetails.requiredAxisDistance;

  const filteredInputDetails = filterInputDetails(inputDetails);

  const mappedInputDetails = Object.keys(filteredInputDetails).map(
    (detailKey) => {
      let inputDetailsValue =
        filteredInputDetails[detailKey as keyof InputDetailsData];

      if (detailKey === 'orientationName') {
        detailKey = isConcrete
          ? 'concreteOrientationName'
          : 'steelOrientationName';
      }

      if (isValueUnitPair(inputDetailsValue)) {
        const value = inputDetailsValue.value + ' ' + inputDetailsValue.unit;
        return {
          name: t(`productDetails.${detailKey}`),
          value: value,
        };
      }

      inputDetailsValue = mapInputDetailsValues(
        t,
        detailKey,
        inputDetailsValue
      );

      return {
        name: t(`productDetails.${detailKey}`),
        value: String(inputDetailsValue),
      };
    }
  );

  return mappedInputDetails;
};

export default mapInputDetails;
