import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import useGetConcreteFireRatings from '../../../../pages/concrete/api/useGetConcreteFireRatings';
import useGetConcreteOrientations from '../../../../pages/concrete/api/useGetConcreteOrientations';
import useGetConcreteStructuresIds from '../../../../shared/hooks/useGetConcreteStructuresIds';
import FormikInput from '../../../input/formik-input';
import Loader from '../../../loader';
import FormikSelect from '../../../select/formik-select';
import FilterButtons from '../../components/filterButtons';
import { FilterInputsProps } from '../../constants';
import {
  ShowHideFiltersMobile,
  StyledArrowIcon,
  StyledForm,
} from '../../styles';
import { ConcreteResultsFilters } from '../index';
import useDataMappingWithUnits from '../../../../shared/hooks/useDataMappingWithUnits';

const ConcreteFilterInputs = ({
  isMobile,
  areFiltersExpanded,
  setAreFiltersExpanded,
}: FilterInputsProps) => {
  const { t } = useTranslation();
  const {
    values: concreteValues,
    setFieldValue,
    setErrors,
  } = useFormikContext<ConcreteResultsFilters>();
  const { mapFireRatingsWithUnits } = useDataMappingWithUnits();
  const [isMounted, setIsMounted] = useState(false);

  const {
    concreteOrientations: structuralElements,
    isLoading: isLoadingStructuralElements,
  } = useGetConcreteOrientations();

  const structuralElementsSelectItems = structuralElements?.map(e => {
    return {
      id: e.id,
      name: t(`concrete.structuralElements.${e.name}`),
    };
  }) ?? [];

  const { beamOrientationId, columnOrientationId } =
    useGetConcreteStructuresIds();

  const { fireRatings, isLoading: isLoadingFireRatings } = useGetConcreteFireRatings();
  const fireRatingsSelectItems = mapFireRatingsWithUnits(fireRatings ?? []);

  useEffect(() => {
    if (
      isMounted &&
      Number(concreteValues.structuralElement) !== beamOrientationId &&
      Number(concreteValues.structuralElement) !== columnOrientationId
    ) {
      setFieldValue('depth', null);
      setFieldValue('width', null);
      setErrors({});
    } else {
      setIsMounted(true);
    }
  }, [concreteValues.structuralElement]);

  const isLoading = isLoadingStructuralElements || isLoadingFireRatings;

  if (isLoading) {
    return <Loader fullScreen={false}/>;
  }

  return (
    <>
      {isMobile && (
        <ShowHideFiltersMobile
          onClick={() => setAreFiltersExpanded(!areFiltersExpanded)}
        >
          {t('results.filters.filters')}
          <StyledArrowIcon $rotate_down={areFiltersExpanded} />
        </ShowHideFiltersMobile>
      )}

      {areFiltersExpanded && (
        <StyledForm $isMobile={isMobile}>
          <FormikSelect
            name="structuralElement"
            label={t('results.filters.concrete.structuralElement') ?? ''}
            items={structuralElementsSelectItems}
            small
          />

          {(Number(concreteValues.structuralElement) === beamOrientationId ||
            Number(concreteValues.structuralElement) ===
              columnOrientationId) && (
            <>
              <FormikInput
                name="depth"
                label={t('results.filters.concrete.depth') ?? ''}
                placeholder={t('results.filters.concrete.depth') ?? ''}
                numberInput
                decimal
                small
              />

              <FormikInput
                name="width"
                label={t('results.filters.concrete.width') ?? ''}
                placeholder={t('results.filters.concrete.width') ?? ''}
                numberInput
                decimal
                small
              />
            </>
          )}

          <FormikInput
            name="currentAxis"
            label={t('results.filters.concrete.currentAxisDistance') ?? ''}
            placeholder={
              t('results.filters.concrete.currentAxisDistance') ?? ''
            }
            numberInput
            decimal
            optional
            small
          />

          <FormikInput
            name="requiredAxis"
            label={t('results.filters.concrete.requiredAxisDistance') ?? ''}
            placeholder={
              t('results.filters.concrete.requiredAxisDistance') ?? ''
            }
            numberInput
            decimal
            small
          />

          <FormikSelect
            name="fireRating"
            label={t('results.filters.concrete.fireRating') ?? ''}
            items={fireRatingsSelectItems}
            small
          />

          <FormikInput
            name="wastage"
            label={t('results.filters.concrete.wastage') ?? ''}
            placeholder={t('results.filters.concrete.wastage') ?? ''}
            numberInput
            decimal
            maxValue={50}
            small
          />

          <FilterButtons displayShowHideFiltersButton={false} />
        </StyledForm>
      )}
    </>
  );
};

export default ConcreteFilterInputs;
