import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestartIcon from 'assets/restart-icon';
import Button from 'components/button';
import useIsMobile from 'shared/hooks/useIsMobile';
import RestartCalculatorModal from '../../components/restart-calculator-modal';

interface ResultsRestartButtonProps {
  onClick: () => void;
}

const ResultsRestartButton = ({ onClick }: ResultsRestartButtonProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <RestartCalculatorModal
        isOpen={isModalOpen}
        handleConfirm={onClick}
        handleClose={() => setIsModalOpen(false)}
      />
      <Button
        startIcon={<RestartIcon />}
        onClick={() => setIsModalOpen(true)}
        content={
          isMobile ? t('results.restart') : t('results.restartCalculator')
        }
      />
    </>
  );
};

export default ResultsRestartButton;
