import { styled } from 'styled-components';
import CloseIcon from 'assets/close-icon';
import { spacing } from 'styles/helpers';
import { theme, typography } from 'styles/theme';

export const ModalHeaderWrapper = styled.div`
  color: ${theme.colors.textModal};
  display: flex;
  font: ${typography.h4};
  justify-content: space-between;
  padding-bottom: ${spacing.s};
`;

export const Title = styled.h4`
  font: ${typography.h4};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.colors.primary};
  height: 24px;
  width: 24px;
`;