import { useTranslation } from 'react-i18next';
import FormikInput from 'components/input/formik-input';
import {
  ContentWrapper, DisclaimerWrapper,
  InputsWrapper,
  StyledAxisIcon,
  Wrapper,
} from './styles';
import { AxisIcon } from '../../../../../assets/concrete-structural-elements/';

import Disclaimer from '../../../../../components/disclaimer-with-background';
import {
  DisclaimerContentWrapper
} from '../../../../../components/disclaimer-with-background/protected-sides-disclaimer/styles';
import useIsMobile from '../../../../../shared/hooks/useIsMobile';
import { SESSION_STORAGE_KEYS } from '../../../../../shared/sessionStorageUtils';
import useLocalStorage from '../../../../../shared/hooks/useLocalStorage';

const countriesToHideDisclaimer = ['AU'];
const AxisInformationInputs = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { getValueFromLocalStorage } = useLocalStorage();
  const selectedCountry = getValueFromLocalStorage(SESSION_STORAGE_KEYS.country);

  return (
    <ContentWrapper>
      <Wrapper>
        <StyledAxisIcon src={AxisIcon} />
        <InputsWrapper>
          <FormikInput
            name="currentAxis"
            label={t('concrete.structuralElements.currentAxis') ?? ''}
            placeholder={t('concrete.structuralElements.axisPlaceholder') ?? ''}
            numberInput
            maxLength={5}
            optional
            tooltipText={t('concrete.structuralElements.tooltipText') ?? ''}
          />
          <FormikInput
            name="requiredAxis"
            label={t('concrete.structuralElements.requiredAxis') ?? ''}
            placeholder={t('concrete.structuralElements.axisPlaceholder') ?? ''}
            numberInput
            maxLength={5}
          />
        </InputsWrapper>
      </Wrapper>
      {
        !countriesToHideDisclaimer.includes(selectedCountry?.code) &&
        <DisclaimerWrapper $isMobile={isMobile}>
          <Disclaimer title={t('concrete.structuralElements.requiredAxisDistance')}>
            <DisclaimerContentWrapper>{t('concrete.structuralElements.requiredAxisDistanceDisclaimer')}</DisclaimerContentWrapper>
          </Disclaimer>
        </DisclaimerWrapper>
      }
    </ContentWrapper>
  );
};

export default AxisInformationInputs;
