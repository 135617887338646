import { SESSION_STORAGE_KEYS } from '../../../shared/sessionStorageUtils';
import { parseJson } from '../../../shared/utils/jsonUtils';

export const getUrlParams = () => {
  const queryParams: string[] = [];
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    const value = key && sessionStorage.getItem(key);

    if (value) {
      const parsedValue = parseJson(value);

      if (parsedValue !== null && parsedValue !== undefined) {
        if (
          key !== SESSION_STORAGE_KEYS.country &&
          key !== SESSION_STORAGE_KEYS.inputParametersFromResults
        ) {
          if (typeof parsedValue === 'object') {
            Object.entries(parsedValue).forEach(
              ([subKey, subValue]: [string, any]) => {
                if (isValueValid(subValue)) {
                  queryParams.push(
                    `${encodeURIComponent(subKey)}=${encodeURIComponent(
                      subValue
                    )}`
                  );
                }
              }
            );
          } else {
            queryParams.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(
                String(parsedValue)
              )}`
            );
          }
        } else if (key === SESSION_STORAGE_KEYS.inputParametersFromResults) {
          Object.entries(parsedValue).forEach(
            ([subKey, subValue]: [string, any]) => {
              if (
                (subKey === 'productId' ||
                  subKey === 'category' ||
                  subKey === 'protectedSideId') &&
                isValueValid(subValue)
              ) {
                queryParams.push(
                  `${encodeURIComponent(subKey)}=${encodeURIComponent(
                    subValue
                  )}`
                );
              }
            }
          );
        } else if (key === SESSION_STORAGE_KEYS.country) {
          Object.entries(parsedValue).forEach(
            ([subKey, subValue]: [string, any]) => {
              if (
                (subKey === 'code' || subKey === 'name') &&
                isValueValid(subValue)
              ) {
                queryParams.push(
                  `${encodeURIComponent(
                    subKey + 'Country'
                  )}=${encodeURIComponent(subValue)}`
                );
              }
            }
          );
        }
      }
    }
  }

  const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  return `${window.location.origin}${window.location.pathname}${queryString}`;
};

const isValueValid = (value: string) => {
  return value !== null && value !== undefined && value !== '';
};
