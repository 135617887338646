import { useTranslation } from 'react-i18next';
import ProductCard from 'components/product-card';
import { CalculationInputParamsData, ProductWithCalculationDetails } from 'shared/types';
import { CardsWrapper, NoResultsText, NoResultsWrapper } from './styles';
import { ButtonVariant } from '../../components/button/types';
import ContactSupportCtaButton from '../../components/contact-support-cta-button';
import React from 'react';
import Loader from '../../components/loader';

interface ResultsGridProps {
  isLoadingData?: boolean;
  products?: ProductWithCalculationDetails[];
  onViewProductDetails: (inputParams: CalculationInputParamsData) => void;
  onContactSupportModalOpen: () => void;
}

const ResultsGrid = ({ isLoadingData, products, onViewProductDetails, onContactSupportModalOpen }: ResultsGridProps) => {
  const { t } = useTranslation();

  if (isLoadingData) {
    return <Loader fullScreen={false}/>;
  }

  return products?.length ? (
    <CardsWrapper>
      {products.map((product) => (
        <ProductCard
          key={product.productDetails.id}
          product={product}
          onClick={() => onViewProductDetails(product.calculationInputParameters)}
        />
      ))}
    </CardsWrapper>
  ) : (
    <NoResultsWrapper>
      <NoResultsText>{t('results.noResults')}</NoResultsText>
      <ContactSupportCtaButton
        onClick={onContactSupportModalOpen}
        variant={ButtonVariant.outlined}
      />
    </NoResultsWrapper>
  );
};
export default ResultsGrid;
