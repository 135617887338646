import { css } from 'styled-components';
import { breakpoint, sizing, spacing } from './helpers';
import { CONTENT_MAX_WIDTH, theme } from './theme';

const THREE_COLUMNS_MAX_COLUMN_WIDTH = `calc(${CONTENT_MAX_WIDTH} - (${spacing.m} * 2) - (${spacing.s} * 2) / 3)`;

export const grid = {
  twoColumnFitContentInFirst: () => css`
    display: grid;
    gap: ${spacing.l};
    grid-template-columns: min-content 1fr;

    ${breakpoint.tablet} {
      grid-template-columns: 1fr;
    }

    ${breakpoint.mobile} {
      grid-template-columns: 1fr;
    }
  `,
  threeColumn: () => css`
    display: grid;
    gap: ${spacing.s};
    grid-template-columns: repeat(
      3,
      minmax(0, ${THREE_COLUMNS_MAX_COLUMN_WIDTH})
    );

    ${breakpoint.tablet} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${breakpoint.mobile} {
      grid-template-columns: 1fr;
    }
  `,
};

export const iconSizing = {
  default: () => css`
    height: ${sizing(6)};
    min-width: ${sizing(6)};
    width: ${sizing(6)};
  `,
  s: () => css`
    height: ${sizing(5)};
    min-width: ${sizing(5)};
    width: ${sizing(5)};
  `,
  xs: () => css`
    height: ${sizing(4)};
    min-width: ${sizing(4)};
    width: ${sizing(4)};
  `,
};

export const rotate = (number: 0 | 90 | 180 | 270) => css`
  transform: rotate(${number}deg);
`;

export const muiTabsStyles = {
  marginBottom: '-2px',
  minHeight: 'unset',

  '& .MuiTabs-indicator': {
    backgroundColor: theme.colors.primary,
  },
};

export const muiTabStyles = {
  minHeight: 'unset',
  minWidth: 'unset',
  padding: spacing['2xs'],
  textTransform: 'none',
};
