import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Formik } from 'formik';
import useGetConcreteStructuresIds from '../../../shared/hooks/useGetConcreteStructuresIds';
import useIsMobile from '../../../shared/hooks/useIsMobile';
import { SESSION_STORAGE_KEYS } from '../../../shared/sessionStorageUtils';
import { parseJson } from '../../../shared/utils/jsonUtils';
import {
  getValueFromSessionStorage,
  saveObjectToSessionStorage,
  saveValueToSessionStorage,
} from '../../../shared/utils/sessionStorageUtils';
import { Container } from '../styles';
import ConcreteFilterInputs from './concrete-filter-inputs';
import useFiltersValidationSchema from './validation';

export interface ConcreteResultsFilters {
  structuralElement: number;
  depth: number | null;
  width: number | null;
  currentAxis: number | null;
  requiredAxis: number;
  fireRating: number;
  wastage: number | 10;
}

interface ConcreteResultFilterProps {
  setIsRecalculating: Dispatch<SetStateAction<boolean>>;
}

const ConcreteResultFilters = ({
  setIsRecalculating,
}: ConcreteResultFilterProps) => {
  const isMobile = useIsMobile();
  const concreteStructuralElements = parseJson(
    getValueFromSessionStorage(
      SESSION_STORAGE_KEYS.concreteStructuralElements
    ) || ''
  );
  const concreteEnvironment = parseJson(
    getValueFromSessionStorage(SESSION_STORAGE_KEYS.concreteEnvironment) || ''
  );
  const concreteFireRating = parseJson(
    getValueFromSessionStorage(SESSION_STORAGE_KEYS.concreteFireRating) || ''
  );

  const sessionStorageValues: ConcreteResultsFilters = {
    structuralElement: concreteStructuralElements?.orientation,
    depth: concreteStructuralElements?.depth ?? '',
    width: concreteStructuralElements?.width ?? '',
    currentAxis: concreteStructuralElements?.currentAxis ?? '',
    requiredAxis: concreteStructuralElements?.requiredAxis,
    fireRating: concreteFireRating,
    wastage: concreteEnvironment?.wastage ?? 10,
  };

  const { validationSchema } = useFiltersValidationSchema();

  const { beamOrientationId, columnOrientationId } =
    useGetConcreteStructuresIds();

  const handleSubmit = (values: ConcreteResultsFilters) => {
    const concreteStructuralElements = [
      {
        key: 'orientation',
        value: Number(values.structuralElement),
        default: '',
      },
      {
        key: 'requiredAxis',
        value: values.requiredAxis,
        default: '',
      },
      {
        key: 'currentAxis',
        value: values.currentAxis,
        default: '',
      },
    ];

    if (
      Number(values.structuralElement) === beamOrientationId ||
      Number(values.structuralElement) === columnOrientationId
    ) {
      concreteStructuralElements.push(
        {
          key: 'depth',
          value: values.depth,
          default: '',
        },
        {
          key: 'width',
          value: values.width,
          default: '',
        }
      );
    }

    saveObjectToSessionStorage(
      SESSION_STORAGE_KEYS.concreteStructuralElements,
      concreteStructuralElements
    );

    saveObjectToSessionStorage(SESSION_STORAGE_KEYS.concreteEnvironment, [
      {
        key: 'buildingFunction',
        value: concreteEnvironment.buildingFunction,
        default: '',
      },
      {
        key: 'areaToProtect',
        value: concreteEnvironment.areaToProtect,
        default: '',
      },
      {
        key: 'wastage',
        value: values.wastage,
        default: 10,
      },
      {
        key: 'humidity',
        value: concreteEnvironment.humidity,
        default: '',
      },
      {
        key: 'temperature',
        value: concreteEnvironment.temperature,
        default: '',
      },
    ]);

    saveValueToSessionStorage(SESSION_STORAGE_KEYS.concreteFireRating, {
      value: values.fireRating.toString(),
      default: '',
    });

    setIsRecalculating(true);
  };

  const [areFiltersExpanded, setAreFiltersExpanded] = useState<boolean>(false);

  useEffect(() => {
    setAreFiltersExpanded(!isMobile);
  }, [isMobile]);

  return (
    <Container $isMobile={isMobile}>
      <Formik
        initialValues={sessionStorageValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {() => {
          return (
            <ConcreteFilterInputs
              isMobile={isMobile}
              areFiltersExpanded={areFiltersExpanded}
              setAreFiltersExpanded={setAreFiltersExpanded}
            />
          );
        }}
      </Formik>
    </Container>
  );
};

export default ConcreteResultFilters;
