import { useField } from 'formik';
import useFormErrorTranslation from 'shared/hooks/useFormErrorTranslation';
import Input, { InputProps } from '.';

type FormikInputProps = {
  name: string;
  showError?: boolean;
  explanation?: string;
  displayArrowButtons?: boolean;
  maxValue?:number;
} & Omit<InputProps, 'onChange'>;

const FormikInput = ({
  name,
  label,
  placeholder,
  optional,
  numberInput,
  decimal,
  showLabel,
  maxLength,
  tooltipText,
  showError = true,
  explanation,
  displayArrowButtons = false,
  maxValue,
  small = false,
}: FormikInputProps) => {
  const [field, meta, helper] = useField(name);
  const { touched, error } = meta;
  const { onChange, value } = field;
  const { setError } = helper;
  const errorTranslation = useFormErrorTranslation(touched, error);

  const handleChange = (value: string) => {
    const formikHandleChangeFn = onChange(name);

    formikHandleChangeFn(value);
    setError(undefined);
  };

  return (
      <Input
        numberInput={numberInput}
        optional={optional}
        label={label}
        placeholder={placeholder}
        error={touched && !!error}
        helperText={showError ? errorTranslation : ''}
        onChange={handleChange}
        value={value ?? ''}
        name={name}
        decimal={decimal}
        showLabel={showLabel}
        maxLength={maxLength}
        tooltipText={tooltipText}
        id={name}
        explanation={explanation}
        displayArrowButtons={displayArrowButtons}
        maxValue={maxValue}
        small={small}
      />
  );
};

export default FormikInput;
