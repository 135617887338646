import { Font, StyleSheet } from '@react-pdf/renderer';
import { radii, sizing, spacing } from 'styles/helpers';
import { theme } from 'styles/theme';

Font.register({
  family: 'Work Sans Regular',
  fonts: [
    {src: require('fonts/WorkSans-Regular.ttf'),}
  ]
});

const styles = StyleSheet.create({
  table: {
    border: theme.border.default,
    borderRadius: radii.s,
    color: theme.colors.black,
    fontSize: sizing(2),
    marginBottom: spacing.s,
  },
  tableRow: {
    borderBottom: theme.border.default,
    flexDirection: 'row',
    minHeight: sizing(6),
  },
  tableRowLast: {
    borderBottom: 0,
    flexDirection: 'row',
    minHeight: sizing(6),
  },
  tableTitleRow: {
    backgroundColor: theme.colors.backgroundLight,
    borderBottom: theme.border.default,
    color: theme.colors.primary,
    fontFamily: 'Avenir Next Cyr W00 Medium',
    justifyContent: 'center',
    minHeight: sizing(6),
    padding: `${spacing['3xs']} ${spacing.xs}`,
    width: '100%',
  },
  detailName: {
    fontFamily: 'Avenir Next Cyr W00 Bold',
    justifyContent: 'center',
    padding: `${spacing['3xs']} 10px ${spacing['3xs']} ${spacing.xs}`,
    width: '50%',
  },
  detailValue: {
    fontFamily: 'Work Sans Regular',
    justifyContent: 'center',
    padding: `${spacing['3xs']} 10px`,
    width: '50%',
  },
});

export default styles;
