import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersIcon } from '../../../assets/filters-icon';
import RestartIcon from '../../../assets/restart-icon';
import Button from '../../button';
import { ButtonVariant } from '../../button/types';
import { ButtonWrapper, ShowHideFiltersButtonWrapper } from './styles';

interface FilterButtonsProps {
  onShowHide?: () => void;
  filtersExpanded?: boolean;
  displayShowHideFiltersButton?: boolean;
}

const FilterButtons = ({
  filtersExpanded,
  onShowHide,
  displayShowHideFiltersButton = true,
}: FilterButtonsProps) => {
  const { t } = useTranslation();
  return (
    <ButtonWrapper>
      {displayShowHideFiltersButton && (
        <ShowHideFiltersButtonWrapper>
          <Button
            content={
              filtersExpanded
                ? t('results.filters.showLessFilters')
                : t('results.filters.showAllFilters')
            }
            endIcon={<FiltersIcon />}
            variant={ButtonVariant.grey}
            onClick={onShowHide}
          />
        </ShowHideFiltersButtonWrapper>
      )}
      <Button
        content={t('results.filters.recalculate')}
        startIcon={<RestartIcon />}
        type={'submit'}
      />
    </ButtonWrapper>
  );
};

export default FilterButtons;
