import { useTranslation } from 'react-i18next';
import { FireRating } from '../types';
import { ProtectedSidesItem, SteelCriticalTemperature } from '../../pages/steel/types';
import parse from 'html-react-parser';


const useDataMappingWithUnits = () => {
  const { t } = useTranslation();

  const mapTemperaturesWithUnits = (criticalTemperatures: SteelCriticalTemperature[]) => {
    return criticalTemperatures.map((r) => {
      return {
        id: `${r.id}`,
        name: `${r.value} ${parse(t('steel.fireRating.criticalTemperaturePlaceholder'))}`,
      };
    });
  };

  const mapFireRatingsWithUnits = (fireRatings: FireRating[]) => {
    return fireRatings.map((r) => {
      return {
        id: r.id,
        name: `${t('fireRating.time', { amount: r.value })}`,
      };
    });
  };

  const mapProtectedSidesWithLabels = (protectedSides: ProtectedSidesItem[][]) => {
    const protectedSidesMap = protectedSides.flatMap((p) => p);
    return protectedSidesMap.map((p) => {
      return {
        id: p.id,
        name:
          p.sidesToProtect === 1
            ? `${t('steel.protectedSides.label.side', {
              count: p.sidesToProtect,
            })} ${p.code}`
            : `${t('steel.protectedSides.label.sides', {
              count: p.sidesToProtect,
            })} ${p.code}`,
      };
    });
  };

  return { mapTemperaturesWithUnits, mapFireRatingsWithUnits, mapProtectedSidesWithLabels };
};

export default useDataMappingWithUnits;