import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/tooltip';
import { Heading4 } from 'components/typography';
import { HS_FORM_PORTAL, HS_FORM_REGION } from '../../shared/constants';
import ContactSupportCtaButton from '../contact-support-cta-button';
import ModalComponent from '../modal';
import HubspotForm from '../modal/modal-form';
import ModalHeader from '../modal/modal-header';
import { TitleWrapper, StyledInfoIcon, CtaButtonWrapper } from './styles';

interface ScreenTitleProps {
  title: string;
  tooltipText?: string;
  tooltipWidth?: number;
  className?: string;
  displayContactSupport?: boolean;
}

const ScreenTitle = ({
  title,
  tooltipText,
  tooltipWidth,
  className,
  displayContactSupport,
}: ScreenTitleProps) => {
  const { t } = useTranslation();
  const contactSupport = t('modal.contactSupport') || '';

  const [open, setOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>(contactSupport);

  return (
    <>
      <ModalComponent
        header={
          <ModalHeader
            title={modalTitle}
            handleClose={() => {
              setOpen(false);
              setModalTitle(contactSupport);
            }}
          />
        }
        isOpen={open}
        content={
          <HubspotForm
            portalId={HS_FORM_PORTAL}
            formId={t('hubspot.contactSupportFormId')}
            region={HS_FORM_REGION}
            onFormSubmitted={() => setModalTitle('')}
            onDoneClick={() => {
              setOpen(false);
              setModalTitle(contactSupport);
            }}
          />
        }
      />

      <TitleWrapper className={className}>
        <Heading4>{title}</Heading4>
        {tooltipText && (
          <Tooltip text={tooltipText} maxWidth={tooltipWidth}>
            <StyledInfoIcon />
          </Tooltip>
        )}
        {displayContactSupport && (
          <CtaButtonWrapper>
            <ContactSupportCtaButton onClick={() => setOpen(true)} />
          </CtaButtonWrapper>
        )}
      </TitleWrapper>
    </>
  );
};

export default ScreenTitle;
